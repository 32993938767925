.select-option {
  font-size: 16px;
  //height: 39px;
  border-radius: 9px;
  background-color: #EEF0F7;
  box-shadow: none;

  @media (min-width: 1024px) {
    height: 64px;
    border-radius: 16px;
  }

  &:after {
    width: 18px;
    height: 18px;
    background: var(--select-dropdown-arrow, url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE5IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjUgMC41TDkuNSA3LjVMMTguNSAwLjUiIHN0cm9rZT0iIzg1ODU4NSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=)) center / 18px 18px no-repeat;
    inset: 0 26px 0 auto;
  }

  &--country {
    .select-option-trigger,
    .select-option-list-item {
      padding-left: 54px;
      //height: 39px;
      //line-height: 39px;

      @media (min-width: 1024px) {
        height: 64px;
        line-height: 64px;
      }

      &--rub {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwaDI0djZIMFYwWiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PHBhdGggc3Ryb2tlPSIjRDBEMEQwIiBkPSJNLjUuNWgyM3YxN0guNVYuNVoiLz48cGF0aCBmaWxsPSIjMDAzOUE2IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDZoMjR2NkgwVjZaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48cGF0aCBmaWxsPSIjRDUyQjFFIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEyaDI0djZIMHYtNloiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
      }

      &--kzt {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAxQTVDOCIgZD0iTTAgMGgyNHYxOEgweiIvPjxwYXRoIHN0cm9rZT0iI0ZGRTcwMCIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBkPSJtOCAxMSA0LjUgM00xMS41IDE0bDQuNS0zIi8+PGNpcmNsZSBjeD0iMTIiIGN5PSI4IiByPSIzIiBmaWxsPSIjRkZFNzAwIi8+PC9zdmc+);
      }

      &--rub,
      &--kzt {
        background-position: center left 27px;
        background-size: 24px 18px;
        background-repeat: no-repeat;
        padding-left: 63px;
        @media (min-width: 1024px) {
          background-position: center left 30px;
          padding-left: 78px;
        }
      }
    }
  }
}
