.auth-rules {
  --tickbox-checkbox-radius: 5px;

  padding: 0;
  display: flex;
  margin-left: 2px;
  margin-top: 5px;

  @media (min-width: 1024px) {
    margin: 8px 0 0 18px;
  }

  label {
    color: #000000;
    font-size: 13px;
    line-height: 1;
    margin-left: -5px;

    @media (min-width: 1024px) {
      font-size: 17px;
      line-height: 22px;
    }

    span:first-child {
      white-space: unset;
      text-overflow: unset;
      overflow: auto;
    }
  }

  a {
    color: #3B7CED;

    &:hover {
      text-decoration: none;
    }
  }
}
