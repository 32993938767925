.btn-signup-send {
  --button-color: White;
  --button-background: linear-gradient(to left, #1784E4, #98CFFF);

  background: var(--button-background);
  color: var(--button-color);
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  user-select: none;
  letter-spacing: 0;
  -webkit-tap-highlight-color: transparent;
  text-transform: none;
  border: none;
  border-radius: 16px;
  outline: none;
  place-content: center;
  width: -moz-fit-content;
  width: 100%;
  height: 48px;
  margin: 0;
  padding: 0 22px;
  font-family: inherit;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  display: grid;
  position: relative;
  overflow: hidden;

  @media (min-width: 1024px) {
    height: 64px;
    margin: auto;
    font-size: 23px;
    --button-background: linear-gradient(to right, #1784E4, #98CFFF);
  }

  @media (hover: hover) and (pointer: fine) {
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(to left, #1784E4, #98CFFF);
      transition: opacity 375ms ease;
    }

    &:hover {
      &::before {
        opacity: 0;
      }
    }

    span {
      position: relative;
      z-index: 1;
    }
  }
}
