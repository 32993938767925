.form {
  position: relative;
  display: grid;
  gap: 10px 0;

  @media (min-width: 1024px) {
    gap: 20px 0;
  }

  &__title {
    display: block;
    color: #000000;
    font-size: 28px;
    line-height: 1;
    text-align: center;
    font-weight: 600;

    @media (min-width: 1024px) {
      font-size: 36px;
      line-height: 64px;
    }
  }

  &__subtitle {
    display: block;
    color: #000000;
    font-size: 15px;
    line-height: 1;
    text-align: center;
    max-width: 250px;
    margin: 5px auto 25px;

    @media (min-width: 1024px) {
      font-size: 20px;
      margin: -10px auto 25px;
      max-width: 350px;
    }
  }

  .input {
    background-color: #EEF0F7;
    border-radius: 9px;
    border: none;
    //height: 39px;

    @media (min-width: 1024px) {
      height: 64px;
      border-radius: 16px;
    }

    input {
      font-size: 15px;
      padding-left: 30px;

      @media (min-width: 1024px) {
        font-size: 20px;
      }
    }

    .floating-label {
      left: 30px;
    }
  }

  .notched-outline {
    &__leading,
    &__notch,
    &__trailing {
      border: none;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #000000;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #00000000;
  }
}
