.signup-form {
  max-width: 560px;
  background-color: #ffffff;
  border-radius: 15px;
  width: min(1140px, 100% - 40px);
  margin: auto;
  padding: 40px 25px;

  @media (min-width: 1024px) {
    padding: 30px 26px 50px;
  }
}
