.tickbox {
  input {
    &[type="checkbox"]:not([role="switch"]) {
      margin-right: 0;
    }
    &[type="checkbox"]:not([role="switch"]):after {
      width: 16px;
      height: 16px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgaGVpZ2h0PSI5IiBmaWxsPSJub25lIj48cGF0aCBzdHJva2U9IiMwQTI2NEIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJtMSA1LjQyMSAyLjk2NiAyLjAzNmExIDEgMCAwIDAgMS4zNjYtLjIyNUwxMCAxIi8+PC9zdmc+);
      background-size: contain;
    }
    &[type="checkbox"]:not([role="switch"]):before {
      width: 25px;
      height: 25px;
      border-radius: 5px;
    }
    &[type="checkbox"]:not([role="switch"]):checked:before {
      background-color: #ffffff;
      border: none;
    }

    &[type="checkbox"]:not([role="switch"]):checked:before {
      background-image: linear-gradient(to right, #3F9FF4, #255D8E);
      border: none;
    }
    &[type="checkbox"]:not([role="switch"]):after {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMyIgZD0ibTEuNSA4LjI0MyA0LjY0NyA2Ljk3NmExIDEgMCAwIDAgMS42NzctLjAyTDE2LjUgMS41Ii8+PC9zdmc+);
    }
    &[type="checkbox"]:not([role="switch"]):before {
      border-color: #b2b2b2;
      background-color: transparent;
    }

    @media (min-width: 1024px) {
      &[type="checkbox"]:not([role="switch"]) {
        margin-right: 10px;
      }
      &[type="checkbox"]:not([role="switch"]) {
        margin-left: -11px;
      }
      &[type="checkbox"]:not([role="switch"]):before {
        width: 30px;
        height: 31px;
        border-radius: 7px;
      }
    }
  }
}
